// import { fromJS } from 'immutable';
import * as core from 'cw-ui-core';
import { GET, POST, PATCH, PUT, DELETE } from 'src/services/ApiService';
import Routes from 'src/services/Routes';
import { put, take } from 'redux-saga/effects';
import history from 'src/services/History';
// import { User as OidcUser } from 'oidc-client';
import parse from 'parse-link-header';
import { getUserContactInfoFromClaims } from 'src/services/ContactClaimsService';

export async function loadAllUsers() {
    const state = core.Store().getState();

    let result = undefined;
    const partnerId = core.getScreenCustom(state.screen, 'partnerId') || '';
    result = await GET(`/user/allusers/${partnerId}`);
    
    if (!result.isSuccess) {
        const error = 'Failed to load users list. ' + result.error;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        return;
    }
    let users = Array.isArray(result.response) ? result.response : [result.response];

    core.Store().dispatch(core.setScreenData([Routes.USERS.id], users));
}
//const PAGE_SIZE = 10;
export async function loadOnlyAdmins(flag) {
    if (flag) {
        const state = core.Store().getState();
        const PAGE_SIZE = core.getModuleCache(state.module, [Routes.USERS.id, 'pager', 'pageSize']) || 1;
        const page = core.getModuleCache(state.module, [Routes.USERS.id, 'pager', 'currentPage']) || 1;       
        const PartnerId = core.getScreenCustom(state.screen, 'partnerId') || '';
        let result = null;
        let roles = await getPartnerRoles(PartnerId);
        const roleAdmin = roles?.filter(role => role.name === "Admin");
        if (roleAdmin.length === 0) {
            result = await GET(`/user/allusers/${PartnerId}`);

        } else {
            let roleAdminId = roleAdmin[0].id;
            result = await GET(`/user/allusers/${PartnerId}/${roleAdminId}`);
        }
        if (!result.isSuccess) {
            const error = 'Failed to Load Users.' + result.error;
            core.CwLog.error(error);
            core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
            return;
        }
        let users = Array.isArray(result.response) ? result.response : [result.response];
        const totalPages = Math.ceil(users.length / PAGE_SIZE);
        var Adminsperpage = users.slice(
            PAGE_SIZE * (page - 1),
            PAGE_SIZE * page
        );
        core.Store().dispatch(core.setModuleCache([Routes.USERS.id, 'pager', 'totalPages'], totalPages || ''));
        core.Store().dispatch(core.setModuleCache([Routes.USERS.id, 'pager', 'currentPage'], page || ''));
        core.Store().dispatch(core.setScreenData([Routes.USERS.id], Adminsperpage));
    } else{
        await loadUserList();
    }
    
}

export async function loadUserList() {

    const state = core.Store().getState();
    const page = core.getModuleCache(state.module, [Routes.USERS.id, 'pager', 'currentPage']) || 1;
    const PAGE_SIZE = core.getModuleCache(state.module, [Routes.USERS.id, 'pager', 'pageSize']) || 1;

    let result = undefined;
    const partnerId = core.getScreenCustom(state.screen, 'partnerId') || '';
    const search = core.getModuleCache(state.module, [Routes.USERS.id, 'search']) || '';
    const searchParam = search.trim();
    if(searchParam.length>0 && searchParam.length <3)
    {
        const error ="Search should be at least 3 characters";
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false));
        core.Store().dispatch(core.setModuleCache([Routes.USERS.id, 'search'], null)); // Clear search after complete
        return;
    }
    result = await GET(`/user/users?partnerId=${partnerId}&search=${searchParam}&page=${page}&pageSize=${PAGE_SIZE}`);
    if (!result.isSuccess) {
        const error = 'Failed to load users list. ' + result.error;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        core.Store().dispatch(core.setModuleCache([Routes.USERS.id, 'search'], null)); // Clear search after complete
        return;
    }
    let users = Array.isArray(result.response) ? result.response : [result.response];
    // ToDo: Once query conditional support added to STS endpoints, this check will be handled by the tenant and query condition
    if (search && partnerId) {
        // Hit search url, users (if any) should include only one entry
        if (users[0] && users[0].partnerId !== partnerId) {
            const error = `Failed to find user ${search} on this partner`;
            core.CwLog.error(error);
            core.Store().dispatch(core.setErrorScreenMessage(error, false));
            core.Store().dispatch(core.setModuleCache([Routes.USERS.id, 'search'], null)); // Clear search after complete
            return;
        }
    }
    // If search term, users list should only be one user total
    let numberOfPages = page;
    if (result.headers) {
        const parsed = parse(result.headers.get('link'));
        if (parsed) {
            if (parsed['last'] && parsed['last']['page']) {
                numberOfPages = parseInt(parsed['last']['page']);
            }
        }
    }
    core.Store().dispatch(core.setModuleCache([Routes.USERS.id, 'pager', 'totalPages'], numberOfPages || page));
    core.Store().dispatch(core.setScreenData([Routes.USERS.id], users));
}

export async function loadUser(partnerId, userId) {
    if (!partnerId || !userId) {
        const error = `Invalid parameters to load user query`;
        core.CwLog.error(error);
        core.Store.dispatch(core.setErrorScreenMessage(error, false));
        return;
    }
    let result = undefined;

    result = await GET(`/user/${partnerId}/users/${userId}`);

    if (!result.isSuccess) {
        const error = `Failed to load user. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        return;
    }

    let roles = await loadUserRoles(partnerId, userId);
    let user = result.response;
    user.contactInfo = getUserContactInfoFromClaims(user.additionalClaims);
    user.roles = roles;

    let clients = await getUserClients(partnerId, userId);
    user.clients = clients;

    if (user && user.loginScheme != null) {
        const validProviders = ['ExternalAuth'];
        user.mfaMethods = {};
        user.mfaMethods.validProviders = validProviders;
    } else {
        let mfaMethods = await loadUserMFAMethods(partnerId, userId);
        user.mfaMethods = mfaMethods;
    }

    // checking for stale contact
    core.Store().dispatch(core.setScreenCustom(['staleContact'], { contactId: user.contactInfo?.contactId, isStaleContact: false }));
    if (user.contactInfo?.contactId) {
        const sfcontact = await POST(`/user/sfcontact/${user.contactInfo?.contactId}`, null);
        if (!sfcontact.isSuccess) {
            core.Store().dispatch(core.setScreenCustom(['staleContact'], { contactId: user.contactInfo?.contactId, isStaleContact: true }));
        }
    }
    core.Store().dispatch(core.setScreenData([Routes.USER.id], user));

    return user;
}

export const getStaleContact = async (contactId) => {
    const sfcontact = await POST(`/user/sfcontact/${contactId}`, null);
    if (!sfcontact.isSuccess) {
        core.Store().dispatch(core.setScreenCustom(['staleContact'], { contactId, isStaleContact: true }));
    }
    else {
        core.Store().dispatch(core.setScreenCustom(['staleContact'], { contactId, isStaleContact: false }));
    }
    return sfcontact;
};

async function getUserRoles(partnerId, userId) {
    if (!partnerId || !userId) {
        const error = `Invalid parameters to load user query`;
        core.CwLog.error(error);
        core.Store.dispatch(core.setErrorScreenMessage(error, false));
        return;
    }
    let result = undefined;

    result = await GET(`/user/${partnerId}/users/${userId}/roles`);

    if (!result.isSuccess) {
        const error = `Failed to load user roles. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        return;
    }
    let roles = result.response || [];
    roles.map(role => (role.isAssigned = true));

    return roles;
}

async function getUserRolesMapping(partnerId, userId, identityRoleIds) {
    if (!partnerId || !userId) {
        const error = `Invalid parameters to load user query`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false));
        return;
    }
    let result = undefined;

    result = await GET(`/user/${partnerId}/users/${userId}/roles/${identityRoleIds}`);

    if (!result.isSuccess) {
        const error = `Failed to load user roles. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        return;
    }
    let rolesMapping = result.response || [];
    return rolesMapping;
}

export async function getColleaguePiRoles(partnerId, userId) {
    if (!partnerId || !userId) {
        const error = `Invalid parameters to load colleague query`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false));
        return;
    }

    let identityRoles = await getUserRoles(partnerId, userId);
    let mappedIdentityRoles = [];
    for (const iRole of identityRoles) {
        let piCheck = await getUserRolesMapping(partnerId, userId, iRole.id);
        let processedCheck = JSON.stringify(piCheck.connectedApps.find(x => x.connectedAppId === 'productinsights'));
        if (processedCheck.includes(iRole.id)) {
            mappedIdentityRoles.push(iRole.name);
        }
    }

    return mappedIdentityRoles;
}

async function getUserMFAMethods(partnerId, userId) {
    if (!partnerId || !userId) {
        const error = `Invalid parameters to load user query`;
        core.CwLog.error(error);
        core.Store.dispatch(core.setErrorScreenMessage(error, false));
        return;
    }
    let result = undefined;

    result = await GET(`/user/${partnerId}/users/${userId}/mfaMethods`);

    if (!result.isSuccess) {
        const error = `Failed to load user MFA details. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        return;
    }
    let mfaMethods = result.response || [];

    return mfaMethods;
}

export async function disableAuthAppMFA(partnerId, userId) {
    if (!partnerId || !userId) {
        const error = `Invalid parameters to disable MFA`;
        core.CwLog.error(error);
        core.Store.dispatch(core.setErrorScreenMessage(error, false));
        return;
    }

    const validProviders = { "Provider": "Authenticator" };

    let result = undefined;

    core.Store().dispatch(core.toggleMask(true));

    result = await POST(`/user/${partnerId}/users/${userId}/disableMFA`, validProviders);

    if (!result.isSuccess) {
        const error = `Failed to disable user MFA details. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        return;
    }

    core.Store().dispatch(core.setSuccessfulScreenMessage('Successfully disabled Auth App MFA.'));
    core.Store().dispatch(core.toggleMask(false));
    return true;
}

async function getUserClients(partnerId, userId) {
    if (!partnerId || !userId) {
        const error = `Invalid parameters to load user clients`;
        core.CwLog.error(error);
        core.Store.dispatch(core.setErrorScreenMessage(error, false));
        return;
    }
    let result = undefined;

    result = await GET(`/client/tenants/${partnerId}/users/${userId}/clients`);

    const clientsResult = await GET(`/client/tenants/${partnerId}/clients`);

    if (!result.isSuccess) {
        const error = `Failed to load user clients. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false));
        return;
    }

    if (!clientsResult.isSuccess) {
        const error = `Failed to load partner clients. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false));
        return;
    }

    const clientsMap = new Map(clientsResult.response.map(x => [x.clientId, x]));

    let clients = result.response || [];
    clients = clients.map(x => clientsMap.get(x)).filter(x => x != null);

    return clients;
}

async function getPartnerRoles(partnerId) {
    if (!partnerId) {
        const error = `Invalid parameters to load roles query`;
        core.CwLog.error(error);
        core.Store.dispatch(core.setErrorScreenMessage(error, false));
        return;
    }
    let result = undefined;

    result = await GET(`/role/${partnerId}`);

    if (!result.isSuccess) {
        const error = `Failed to load partner roles. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false));
        return;
    }
    let roles = result.response || [];

    return roles;
}

export async function loadPartnerRoles(partnerId, userId = null) {
    let roles = await getPartnerRoles(partnerId);
    const identityRoleIds = roles.map(function (m) {
        return m.id;
    });
    let rolesMapping = await getUserRolesMapping(partnerId, userId, String(identityRoleIds));
    core.Store().dispatch(core.setScreenCustom(['rolesMapping'], rolesMapping));
    core.Store().dispatch(core.setScreenCustom(['partnerRoles'], roles));
}

export async function loadUserRoles(partnerId, userId) {
    let roles = await getUserRoles(partnerId, userId);
    core.Store().dispatch(core.setScreenCustom(['roles'], roles));
}

export async function loadUserMFAMethods(partnerId, userId) {
    let mfaMethods = await getUserMFAMethods(partnerId, userId);
    return mfaMethods;
}

export async function loadUsersHaveClients(users) {
    if (!users) return null;

    Promise.all(users.map(user => getUserClients(user.partnerId, user.id))).then(results => {
        const clientsMap = new Map(results.map((clients, index) => [users[index].id, clients.length > 0]));
        core.Store().dispatch(core.setScreenCustom(['clientsMap'], clientsMap));
    });
}

export async function loadUsersRoles(users) {
    if (!users) return null;

    Promise.all(users.map(user => getUserRoles(user.partnerId, user.id))).then(results => {
        const rolesMap = new Map(results.map((roles, index) => [users[index].id, roles]));
        core.Store().dispatch(core.setScreenCustom(['rolesMap'], rolesMap));
    });
}

//Prepare the colleague's filtered identity roles as a string to be stored in cache
export async function loadColleaguePiRoles(partnerId, userId, filteredIdentityRoles) {
    let matchingPiRoles = await getColleaguePiRoles(partnerId, userId);
    if ((filteredIdentityRoles !== undefined) && (filteredIdentityRoles.length > 0)) {
        matchingPiRoles = matchingPiRoles.concat(filteredIdentityRoles);
    }
    let rolesString = "";
    rolesString = matchingPiRoles.join(",  ");
    core.Store().dispatch(core.setModuleCache(['matchingPiRoles'], rolesString));
    return rolesString;
}

// When refreshing user data, clear existing map for UX clarity
export async function clearUsersRoles() {
    core.Store().dispatch(core.setScreenCustom(['rolesMap'], null));
}

export async function loadUserPermissions() {
    const permissionsResult = await GET(`/user/permissions`);

    if (!permissionsResult.isSuccess) {
        const error = `Failed to load user permissions. ${permissionsResult.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, permissionsResult.errorMessages));
        return false;
    }

    const permissionsMap = {};

    let modules = permissionsResult.response;
    for (let index in modules) {
        if (modules[index]['module_name']) {
            let module = modules[index];
            permissionsMap[module['module_name']] = module;
        }
    }

    core.Store().dispatch(core.setModuleCache(['permissions'], permissionsMap));

    return true;
}

export async function migrateUser() {
    const state = core.Store().getState();

    const update = core.getScreenUpdated(state.screen, [Routes.USER.id]) || new Map();
    const user = core.getScreenData(state.screen, [Routes.USER.id]).toJS();

    if (update.size === 0 || update.get('newPartnerId') == null) {
        core.Store().dispatch(core.setErrorScreenMessage('Must select new partner.', false, [], 'reassignDialog'));
        return;
    }

    core.Store().dispatch(core.toggleMask(true));
    const result = await POST(`/user/${user.partnerId}/users/${user.id}/migrateUser`, update);

    if (!result.isSuccess) {
        const error = 'Error: ' + result.error;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages, 'reassignDialog'));
        core.Store().dispatch(core.toggleMask(false));
        return;
    }

    core.Store().dispatch(core.removeAllScreenMessages('reassignDialog'));
    core.Store().dispatch(core.setSuccessfulScreenMessage('User has successfully been reassigned.', false));
    core.Store().dispatch(core.setScreenData([Routes.USER.id], result.response));
    core.Store().dispatch(core.removeScreenUpdated());
    core.Store().dispatch(core.hideDialog('reassign_user_dialog'));
    core.Store().dispatch(core.toggleMask(false));

    return {
        newPartnerId: update.get('newPartnerId'),
        userId: user.id,
    };
}

export async function editUser() {
    const state = core.Store().getState();
    // return if there are no updates
    if (!core.isDirty(state.screen)) {
        return true;
    }

    let updates = core.getScreenUpdated(state.screen, [Routes.USER.id]) || new Map();
    const user = core.getScreenData(state.screen, [Routes.USER.id]).toJS();

    if (updates.has('contactInfo')) {
        const contactUpdateResult = await POST(`/user/${user.partnerId}/users/${user.id}/contact`, {
            contactId: updates.get('contactInfo').get('contactId'),
        });

        if (!contactUpdateResult.isSuccess || !contactUpdateResult.response.success) {
            const error = 'Failed to update user. ' + contactUpdateResult.error;
            core.CwLog.error(error);
            core.Store().dispatch(core.setErrorScreenMessage(error, false, contactUpdateResult.errorMessages));
            core.Store().dispatch(core.toggleMask(false));
            return false;
        }

        updates = updates.delete('contactInfo');
    }

    core.Store().dispatch(core.toggleMask(true));
    const result = await PATCH(`/user/${user.partnerId}/users/${user.id}`, updates);

    if (!result.isSuccess) {
        const error = 'Failed to update user. ' + result.error;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        core.Store().dispatch(core.toggleMask(false));
        return false;
    }

    core.Store().dispatch(core.setSuccessfulScreenMessage('Successfully updated user.'));
    core.Store().dispatch(core.toggleMask(false));
    return true;
}

export async function editUserRoles() {
    const state = core.Store().getState();
    const user = core.getScreenData(state.screen, [Routes.USER.id]).toJS();
    if (!user.updatedAppRoles) return;

    core.Store().dispatch(core.toggleMask(true));

    const newRoleMapping = {
        connectedAppRoles: user.updatedAppRoles,
    };

    const result = await PUT(`/user/${user.partnerId}/users/${user.id}/roles/cwhome_code`, newRoleMapping);
    if (!result.isSuccess) {
        const error = `Failed to update user roles. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        core.Store().dispatch(core.toggleMask(false));
        return false;
    }

    core.Store().dispatch(core.toggleMask(false));
    return true;
}

export async function deleteUser() {
    const state = core.Store().getState();

    const user = core.getScreenData(state.screen, [Routes.USER.id]).toJS();

    core.Store().dispatch(core.toggleMask(true));

    const result = await DELETE(`/user/${user.partnerId}/users/${user.id}`);

    if (!result || !result.isSuccess) {
        let msg = '';
        if (result) {
            if (result.status === 403) msg = 'You are not authorized to take this action.';
            else msg = result.error;
        }
        const error = `Failed to delete user. ${msg}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
    }

    core.Store().dispatch(core.toggleMask(false));

    history().goBack();
}

export async function getPartnerUsersStats(partnerId, userId) {
    const result = await GET(`/user/${partnerId}/users/${userId}/stats`);

    if (!result.isSuccess) {
        const error = `Failed to find partner status. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        return;
    }

    core.Store().dispatch(core.setScreenCustom(['partnerStats'], result.response));
    return result.response;
}

export const getSalesforceContactLink = contactId => {
    const getUrl = contactId => {
        const integrations = core.getModuleConfig(core.Store().getState().module, ['integrations']);
        const salesforceUrl = integrations.get('salesforceUrl');
        return `${salesforceUrl}/lightning/r/Contact/${contactId}/view`;
    };

    return getUrl(contactId);
};

export function* confirmDeleteRequest(message) {
    if (!message) return;
    yield put(
        core.showDialog('confirm_dialog', {
            message: message,
            textNo: 'Cancel',
            textYes: 'Confirm',
        })
    );

    const { type } = yield take(['CONFIRM_DIALOG_CONFIRMED', 'CONFIRM_DIALOG_CANCELLED']);

    if (type === 'CONFIRM_DIALOG_CONFIRMED') {
        deleteUser();
    }

    return;
}

export async function getUserById(partnerId, userId) {
    const result = await GET(`/user/${partnerId}/users/${userId}`);

    if (!result.isSuccess) {
        const error = `Failed to load user detail. ${result.error}`;
        core.CwLog.error(error);
        core.Store().dispatch(core.setErrorScreenMessage(error, false, result.errorMessages));
        return;
    }

    core.Store().dispatch(core.setScreenCustom(['userDetail'], result.response));
    return;
}